import React, { useState, FC, useEffect } from "react";
import Layout from "../../components/layout/Layout";
import MyAccountList from "../../components/common/List/MyAccountList";
import AddressComponent from "../../components/AddressComponent/UserAddress";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import Breadcrumb from "../../components/common/Breadcrumb";
import { Crumb } from "../../types";
import { navigate, Script } from "gatsby";
import PrivateComponent from "../../utils/PrivateComponent/PrivateComponent";
import { Helmet } from "react-helmet";
import Clickmagic from "../../components/common/ClickMagic/Clickmagic";

const Address = () => {
    // const { isLoggedIn, userId, email } = useJWTChecker();
    const [crumbs, setCrumbs] = useState<Crumb[]>([]);

    useEffect(() => {
        setCrumbs([{ label: "My Addresses", link: null }]);
    }, []);

    return (
        <PrivateComponent>
            <>
                <Layout>
                    <div className="px-[15px] md:px-[27px] xl:px-[88px] lg:px-[50px] md:mb-[100px] mb-[50px] home-faq fadeInUp-animation contain">
                        <Breadcrumb crumbs={crumbs} />
                        <div className="flex md:flex-row flex-col md:gap-8 gap-3">
                            <MyAccountList selectedOption={"My Addresses"} />
                            <div className="md:w-3/4 w-full flex flex-col mt-4">
                                <AddressComponent />
                            </div>
                        </div>
                    </div>
                </Layout>
            </>
        </PrivateComponent>
    );
};


export default Address;

export const Head = (props: any) => {
    return (
        <>
            <title>My Address</title>
            
        </>
    );
};