import React, { FC, useEffect } from "react";
import { useFormik } from "formik";
import Address from "../Checkout/Address";
import addressValidationSchema, {
  billingValidationSchema,
} from "../../utils/ValidationSchema/addressValidation";
import useJWTChecker from "../../utils/Custom-hook/useJWTChecker";
import { useDispatch, useSelector } from "react-redux";
import { rootReducer } from "../../store";
import { getCountry } from "../../services/woocommerceServices/Checkout";
import Button from "../common/Buttons/Button";
import { CloseIcon } from "../common/Svg";

interface Props {
  editAddressType: string;
  editAddressData: any;
  buttonLoading: boolean;
  onPressSaveAddress: Function;
  onPressCancelEdit: Function;
}

const EditAddress: FC<Props> = ({
  editAddressType,
  editAddressData,
  buttonLoading,
  onPressSaveAddress,
  onPressCancelEdit,
}) => {
  const initialValues = {
    first_name: "",
    last_name: "",
    company_name: "",
    country_name: "",
    address_1: "",
    address_2: "",
    town: "",
    state: "",
    zip_code: "",
    phone: "",
  };

  const billingInitialValues = {
    ...initialValues,
    email: "",
  };

  const { isLoggedIn, email, userId } = useJWTChecker();
  const dispatch = useDispatch();
  const { countries, states }: any = useSelector(
    (state: rootReducer) => state.user
  );

  useEffect(() => {
    if (isLoggedIn) {
      const fields = [
        "first_name",
        "last_name",
        "address_1",
        "address_2",
        "zip_code",
        "phone",
        "email",
      ];

      const formik =
        editAddressType == "Billing" ? billingFormik : shippingFormik;
      const addressValues =
        editAddressType == "Billing"
          ? editAddressData.billing
          : editAddressData.shipping;

      fields.forEach((field) => {
        formik.setFieldValue(field, addressValues?.[field] || "");
      });
      formik.setFieldValue("town", addressValues.city);
      formik.setFieldValue("zip_code", addressValues.postcode);
      formik.setFieldValue("phone", addressValues.phone);
      formik.setFieldValue("company_name", addressValues.company);
      const selectedCountryIdx = countries.findIndex(
        (country: any) => country.value === addressValues.country
      );

      if (selectedCountryIdx > -1) {
        formik.setFieldValue("country", countries[selectedCountryIdx]);
        formik.setFieldValue(
          "country_name",
          countries[selectedCountryIdx]?.value
        );

        getCountry(addressValues.country).then((country: any) => {
          // dispatch(setStateList(country.states));
          const stateIndex = country.states.findIndex(
            (state: any) => state.code === addressValues.state
          );

          if (stateIndex > -1)
            formik.setFieldValue("stateObj", {
              label: country.states[stateIndex].name,
              value: country.states[stateIndex].code,
            });
          formik.setFieldValue("state", country.states?.[stateIndex]?.code ?? "");
        });
      }
    }
  }, [isLoggedIn, countries]);

  const billingFormik = useFormik({
    initialValues: billingInitialValues,
    validationSchema: billingValidationSchema,
    onSubmit: (values, { resetForm }) => {
      // resetForm();
    },
  });

  const shippingFormik = useFormik({
    initialValues,
    validationSchema: addressValidationSchema,
    onSubmit: (values, { resetForm }) => {
      // resetForm();
    },
  });

  const handleAddressChange = (e: any, section: string) => {
    // console.log("e.value", e.value, section);

    if (section === "billing") {
      billingFormik.setFieldValue("country_name", e.value);
      billingFormik.setFieldValue("country", e);
      billingFormik.setFieldValue("state", "")
      billingFormik.setFieldValue("stateObj", "")
    } else {
      shippingFormik.setFieldValue("country_name", e.value);
      shippingFormik.setFieldValue("country", e);
      shippingFormik.setFieldValue("state", "")
      shippingFormik.setFieldValue("stateObj", "")
    }
  };

  const handleStateChange = (e: any, section: string) => {
    // console.log("e.value", e.value);
    if (section === "billing") {
      billingFormik.setFieldValue("state", e.value);
      billingFormik.setFieldValue("stateObj", e);
    } else {
      shippingFormik.setFieldValue("state", e.value);
      shippingFormik.setFieldValue("stateObj", e);
    }
  };

  const handlePressCofirmEdit = async () => {
    let formikValues;
    if (editAddressType == "Billing") {
      formikValues = billingFormik.values;
    } else {
      formikValues = shippingFormik.values;
    }
    const addressValue = {
      ...formikValues,
      city: formikValues.town,
      country: formikValues.country_name,
      postcode: formikValues.zip_code,
      company: formikValues.company_name
    };

    if (editAddressType === "Billing") {
      billingFormik.handleSubmit();

      const billingErrors = await billingFormik.validateForm();

      if (Object.keys(billingErrors).length > 0) {
        return;
      }
    } else {
      shippingFormik.handleSubmit();
      const shippingErrors = await shippingFormik.validateForm();

      if (Object.keys(shippingErrors).length > 0) {
        return;
      }
    }

    onPressSaveAddress(addressValue);
  };

  return (
    <div>
      <div className="max-w-full bg-white border rounded-[12px] ">
        <div className="py-3 px-3 flex justify-between items-center rounded-t-[12px] bg-bg-grey border-b">
          <h2 className="text-[20px] font-sofiaSemibold font-semiBold text-black  text-center">
            Edit {editAddressType} Address
          </h2>
          <button
            onClick={() => {
              onPressCancelEdit();
            }}
            className="flex items-center w-8 text-gray-500 hover:text-gray-700 text-xl my-2"
          >
            <CloseIcon />
          </button>
        </div>
        {/* heade */}
        {/* body */}
        <div className="px-3 pt-3 edit-address">
          {editAddressType == "Billing" && (
            <div className="flex  items-start w-full">
              <Address
                showShippingEmailField={true}
                formik={billingFormik}
                handleCountryNameChange={(values) =>
                  handleAddressChange(values, "billing")
                }
                handleStateNameChange={(e: any) =>
                  handleStateChange(e, "billing")
                }
              />
            </div>
          )}
          {/* end billing address */}
          {editAddressType == "Shipping" && (
            <div className="flex  items-start w-full">
              <Address
                showShippingEmailField={false}
                formik={shippingFormik}
                handleCountryNameChange={(values) =>
                  handleAddressChange(values, "shipping")
                }
                handleStateNameChange={(e: any) =>
                  handleStateChange(e, "shipping")
                }
              />
            </div>
          )}
          {/* end shipping address */}
        </div>

        {/* buttons */}
        <div className="flex md:flex-row flex-col justify-end gap-5 md:mt-5 mb-8 px-3">
          <Button
            onClick={() => {
              handlePressCofirmEdit();
            }}
            type="submit"
            text="Save"
            className="submit-btn bg-primary hover:bg-black text-[20px] font-sofiaMedium font-medium w-full  py-3 text-white rounded-[35px] transition-all ease-in "
            loading={buttonLoading}
            disabled={buttonLoading}
          />

          <Button
            onClick={() => {
              onPressCancelEdit();
            }}
            type="submit"
            text="Cancel"
            className="submit-btn !bg-bg-grey hover:!bg-black text-[20px] font-sofiaMedium font-medium w-full  py-3 !text-black hover:!text-white rounded-[35px] transition-all ease-in "
          />
        </div>
      </div>
    </div>
  );
};

export default EditAddress;
